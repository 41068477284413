@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st15-loginpopup {
    position: fixed;
    width: 100%;
    height: 100%;

    right: -100%;
    pointer-events: none;

    z-index: 1000;
    background-color: var(--color-white);// var(--color-dark-yellow);
    transition: right 0.3s ease-in-out;

    @include respond-below(l) {
        top: 0;
    }

    @include respond-above(l) {
        width: rem(331px);
        top: rem($header-height-dsk);
        height: auto;
    }

    &--open {
        right: 0;
        pointer-events: all;
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        right: rem(23px);
        top: rem(27px);
        font-size: rem(20px);

        @include respond-above(l) {
            display: none;
        }
    }

    &__content {
        padding-top: rem(70px);
        font-size: rem(24px);
        line-height: 1em;
        padding-left: rem(22px);
        padding-right: rem(22px);

        @include respond-above(l) {
            padding-top: rem(20px);
        }
    }

    &__item {
        margin-top: rem(27px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-above(l) {
            margin-top: rem(15px);
        }

        &--first {
            margin-top: 0;
        }

        &::after {
            content: '';
            background-image: url(../../../../../resources/saporie/images/black_left_arrow.svg);
            display: block;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            width: rem(13px);
            height: rem(16px);
        }
    }

    &__btn {
        @extend %buttonReset;
        font-weight: 400;
        color: var(--color-ground-brown);
        font-size: rem(16px);
        pointer-events: none;

        &--title {
            font-weight: 700;
            text-decoration: underline;
            font-size: rem(20px);
        }

        &:hover {
            color: var(--color-ground-brown);
        }
    }

    &__hello {
        @extend %font-display;
        margin-bottom: 0;
    }

    &__logout {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;

        padding-left: rem(22px);
        padding-right: rem(22px);
        font-weight: 700;
        text-decoration: underline;
        padding-top: rem(35px);
        padding-bottom: rem(20px);

        @include respond-above(l) {
            position: static;
        }
    }

    &__logo {
        @extend %buttonReset;
        background-color: var(--color-ground-brown);
        background-image: url('../../../../../resources/saporie/images/exit-icon.png');
        background-size: rem(20px);
        background-position: center;
        background-repeat: no-repeat;
        width: rem(35px);
        height: rem(35px);
        border-radius: 50%;
        display: block;
        margin-right: rem(10px);
    }
}
