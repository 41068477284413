@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st2-paginator {
    margin-top: rem(41px);
    margin-bottom: rem(42px);
    display: flex;
    justify-content: center;

    @include respond-above(l) {
        margin-top: rem(95px);
    }

    &__pageItems {
        display: flex;
        justify-content: center;
    }

    &__pageItem,
    &__pageBack,
    &__pageNext {
        display: block;
        cursor: pointer;
        @extend %buttonReset;
    }

    &__pageBack,
    &__pageNext {
        display: inline-flex;
        align-items: center;
        color: var(--color-light-brown);

        & > span {
            font-size: rem(24px);
        }
    }
    &__pageBack {
        margin-right: rem(33px);
    }
    &__pageNext {
        margin-left: rem(33px);
    }

    &__pageItem {
        min-width: rem(30px);
        height: rem(30px);
        line-height: rem(30px);
        margin-right: rem(10px);
        text-align: center;
        @extend %font-paragraph;
        color: var(--color-black);

        &:last-child {
            margin-right: 0;
        }

        &--active {
            font-weight: 700;

            border: none;

            &:hover {
                color: var(--color-black);
            }
        }
    }
}
