@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.ss1-listing {
    @extend %container;

    &--tag {
        //tag version of page
    }

    &__top {
        &--dish {
            display: flex;
            flex-direction: column;
            margin-bottom: rem(20px);
            @include respond-above(l) {
                flex-direction: row;
            }
            picture {
                border-radius: rem(12px); 
            }
        }
    } 

    &__topLeft {
        &--dish {
            max-height: rem(500px);
            width: 100%;
            height: 100%;

            @include respond-above(l) {
                margin-right: rem(30px);
                width: rem(532px);
                flex-shrink: 0;
            }
        }
    }

    /* adjust top right components style */
    &__topRight {
        &--dish {
            width: 100%;
            height: rem(230px);

            @include respond-above(l) {
                height: rem(450px);
                flex-grow: 1;
                flex-shrink: 1;
                width: rem(200px);
            }

            .sc2-recipe-in-evi {
                height: inherit;

                .sc2-recipe-in-evi__container {
                    height: inherit;

                    .sc2-recipe-in-evi__media {
                        height: inherit !important;
                    }
                }
            }

            .sc4-picture {
                height: inherit;
                width: 100%;
            }

            .sc12-video {
                height: inherit;
                width: 100%;
            }
        }
    }

    &__title { 
        @extend %headingReset;
        @extend %font-roboto;
        @extend %font-h1;
        font-weight: 700;
    }

    &__description {
        margin-top: rem(11px);
        font-weight: 400;
        @extend %font-paragraph;

        a {
            color: var(--color-light-brown);
            text-decoration: none;
        }
    }

    &__filters {
        margin-top: rem(32px);
    }

    &__content {
        margin-top: rem(11px);
        @include respond-above(l) {
            margin-top: rem(31px);
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 4px;

        @include respond-above(m) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include respond-above(l) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 8px;
        }
    }

    &__cards--dish {
        display: flex;
        flex-direction: row;
    }

    &__card-container--dish {
        grid-column: span 2;
        display: flex;
        justify-content: flex-start;

        @include respond-below(m) {
            .st1-recipe-card__bottom {
                justify-content: flex-end;
            }
            .st1-recipe-card__chef {
                display: none;
            }
        }

        @include respond-above(l) {
            justify-content: center;
            grid-column: span 3;
        }
    }

    &__card-container {
        display: flex;
        justify-content: flex-start;
        margin-bottom: rem(20px);
     
        @include respond-below(m) {
            .st1-recipe-card__bottom {
                justify-content: flex-end;
            }
            .st1-recipe-card__chef {
                display: none;
            }

            justify-content: center; 
        }

        @include respond-above(l) {
            margin-bottom: rem(32px);
        }
    }

    &__readMore {
        margin-top: rem(96px);
        padding-top: rem(48px);
        padding-bottom: rem(48px);
        border-top: 1px solid var(--color-salmon-rose);
        border-bottom: 1px solid var(--color-salmon-rose);
        margin-bottom: rem(10px);
    }

    &__readMoreTitle {
        @extend %headingReset;
        font-weight: 600;
        @extend %font-h3;
    }

    &__readMoreText {
        margin-top: rem(8px);
        font-weight: 400;
        @extend %font-paragraph;
        max-width: rem(613px);
    }

    &__readMoreLink {
        @extend %font-paragraph;
        font-weight: 600;
        text-decoration: underline;
        color: var(--color-light-brown);
    }

    &__dishRecipes {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include respond-below(l) {
            width: 100%;
        }
    }
}
