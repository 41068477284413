.button-tertiary, .button-primary {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  border-radius: 0;
  text-align: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Blue outline removal (not very accessible) */
  /* Remove excess padding and border in Firefox 4+ */
}
.button-tertiary:focus, .button-primary:focus {
  outline: none;
}
.button-tertiary::-moz-focus-inner, .button-primary::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button-tertiary, .button-primary {
  font-family: "DM Sans";
}

.button-tertiary, .button-primary {
  font-weight: 700;
}

.button-primary {
  --btn-height: 3.25rem;
  font-size: 1rem;
  line-height: var(--btn-height);
  height: var(--btn-height);
  width: 100%;
  color: var(--color-white);
  background-color: var(--color-brown);
  border-radius: 0.75rem;
  padding-inline: 1.25rem;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .button-primary {
    width: fit-content;
  }
}
.button-primary:hover, .button-primary:focus-visible {
  background-color: hsla(var(--color-brown-h), var(--color-brown-s), var(--color-brown-l), 90%);
}

.button-tertiary {
  --btn-height: 3.5rem;
  --border-width: 2px;
  font-size: 1rem;
  line-height: calc(var(--btn-height) - 2 * var(--border-width));
  height: var(--btn-height);
  width: 100%;
  color: var(--color-brown);
  background-color: var(--color-white);
  border: 2px solid var(--color-light-brown);
  border-radius: 0.75rem;
  padding-inline: 1.875rem;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .button-tertiary {
    width: fit-content;
  }
}
.button-tertiary:hover, .button-tertiary:focus-visible {
  color: var(--color-light-brown);
}