@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.st9-filters {
    --zindex: 9;
    width: 100%;
    background-color: var(--color-white);

    &__tabsContainer {
        @extend %container;
    }

    &__hidden {
        opacity: 0;
        height: 0;
    }

    &__tabs {
        display: flex;
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        border-bottom: 1px solid alpha(--color-ground-brown, 0.32);
        overflow-y: scroll;

        @include respond-above(l) {
            overflow-y: hidden;
            justify-content: center;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    &__tab {
        padding-bottom: rem(16px);
        flex: 0 0 auto;
        padding-left: rem(16px);
        padding-right: rem(16px);
        text-transform: uppercase;
        cursor: pointer;
        padding-top: rem(24px);

        &.active {
            border-bottom: 3px solid var(--color-ground-brown);
            font-weight: 700;
        }

        &:focus-visible {
            outline: 1px solid var(--color-black);
            outline-offset: -2px;
        }
    }

    &__tabsContent {
        display: none;
        @include respond-above(l) {
            display: block;
        }
    }

    &__form {
        align-items: center;
        padding-inline: 0;
        display: none;

        &.active {
            display: flex;
        }

        fieldset {
            display: flex;
            gap: rem(8px);
            flex-wrap: wrap;
        }
    }

    &__filter {
        position: relative;
        display: inline-block;
        flex: 0 0 auto;
        border-radius: rem(16px);
        color: var(--color-black);
        border: 1px solid lightness(var(--color-black), 70%);
    }

    &__filter--active {
        border-color: var(--color-black);
    }

    &__label {
        @extend %buttonReset;
        @extend %font-paragraph;
        font-size: rem(17px);
        font-weight: 500;
        padding-left: rem(20px);
        padding-right: rem(10px);
        height: rem(56px);

        &:focus-visible {
            border-radius: rem(16px);
            outline: 1px solid var(--color-black);
        }
    }

    &__arrow {
        display: inline-block;
        content: url('../../../../../resources/saporie/images/down_arrow_black.svg');
        width: 1rem;
        height: rem(8px);
        transition: transform 0.25s ease-in-out;
        margin-left: rem(10px);
    }

    &__filter--active &__arrow {
        display: inline-block;
        content: url('../../../../../resources/saporie/images/down_arrow_white.svg');
        width: 1rem;
        height: rem(8px);
        transform: rotate(-180deg);
        filter: invert(1);
    }

    &__dropdown {
        display: block;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        height: rem(264px);
        overflow: hidden;
        background-color: white;
        color: black;
        border-radius: 12px;
        border: 1px solid var(--color-black);
        top: calc(100% + 8px);
        cursor: default;
        z-index: 999;

        &--toRight {
            left: 0;
        }

        &--toLeft {
            right: 0;
        }
    }

    &__dropdown:focus-visible,
    &__checkbox:focus-visible {
        outline: 1px solid var(--color-black);
    }

    &__list {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom: rem(20px);
        height: rem(264px);
        padding: 0 rem(24px);
    }

    &__item {
        padding-top: rem(20px);
        font-weight: normal;
        width: rem(180px);
    }

    &__checkbox {
        @include checkbox;
    }

    &__filter--active &__dropdown {
        visibility: visible;
        pointer-events: all;
    }

    &__drawer {
        position: fixed;
        z-index: calc(var(--zindex) + 1);
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        transition: right 0.3s ease-in-out;
        background-color: var(--color-white);
        overflow: hidden;

        @include respond-above(l) {
            display: none;
        }

        &--open {
            right: 0;
        }
    }

    &__formMobile {
        height: 100%;
        width: 100%;
        display: none;
        &.active {
            display: block;
        }
    }

    &__titleMobile {
        color: var(--color-white);
        background-color: var(--color-brown);
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        @extend %container;
        display: flex;
        align-items: center;
        @extend %font-h4;

        &--spaced {
            justify-content: space-between;
        }
    }

    &__closeMobile {
        @extend %buttonReset;
        font-size: rem(18px);
    }

    &__drawerRoot {
        background-color: var(--color-white);
        position: absolute;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        transition: right 0.3s ease-in-out;
        overflow-y: auto;

        &--open {
            right: 0;
        }
    }

    &__listMobile {
        padding-bottom: rem(70px);
        padding-left: 0;
        list-style: none;
    }

    &__backMobile {
        @extend %buttonReset;
        margin-right: rem(20px);

        &::after {
            content: '';
            background-image: url(../../../../../resources/saporie/images/black_left_arrow.svg);
            filter: invert(1);
            display: block;
            transform: rotate(180deg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            width: rem(15px);
            height: rem(18px);
        }
    }

    &__itemMobile {
        @extend %container;
        padding-top: rem(18px);
        padding-bottom: rem(18px);
        border-bottom: 1px solid alpha(--color-ground-brown, 0.16);
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        & > button {
            @extend %buttonReset;

            &::after {
                content: '';
                background-image: url(../../../../../resources/saporie/images/black_left_arrow.svg);
                display: block;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                width: rem(15px);
                height: rem(18px);
            }
        }

        &--checkbox {
            padding: 0;
        }

        & > span {
            pointer-events: none;
        }
    }

    &__mobCheckbox {
        margin-bottom: 0;
        display: block;
        width: 100%;

        & > input {
            opacity: 0;
            position: absolute;
        }

        & > span {
            display: block;
            width: 100%;
            padding-top: rem(18px);
            padding-bottom: rem(18px);
            @extend %container;
        }

        .checkbox:checked + .label {
            color: var(--color-white);
            background-color: var(--color-light-brown);
        }
    }

    &__applyMobile,
    &__applyMobileIn {
        @extend %buttonReset;
        position: absolute;
        height: rem(50px);
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 53px);
        max-width: rem(322px);
        bottom: rem(27px);
        text-align: center;
        @extend %font-link;
        font-weight: 600;
        color: var(--color-black);

        background-color: var(--color-dark-yellow);
        box-shadow: -13px 7px 0px 0px alpha(--color-dark-yellow, 0.5);
        border: none;

        &:hover {
            color: var(--color-black);
        }

        &--fixed {
            position: fixed;
        }
    }

    &__applyMobile + &__listMobile {
        padding-bottom: reM(57px);
    }

    &__mobileBtn {
        @extend %buttonReset;
        position: fixed;
        right: rem(20px);
        bottom: rem(10px);
        z-index: var(--zindex);
        background-color: var(--color-brown);
        width: rem(48px);
        height: rem(48px);
        border-radius: 50%;
        transition: opacity 0.3s ease-in-out;

        @include respond-above(l) {
            display: none;
        }

        &--hidden {
            display: none;
        }

        &--invisible {
            opacity: 0;
            pointer-events: none;
        }

        & > span:first-child:empty {
            display: inline-block;
            width: inherit;
            height: inherit;
            background-image: url(../../../../../resources/saporie/images/icon-filtra.png);
            background-repeat: no-repeat;
            background-position: center;
            filter: invert(1);
        }
    }

    &__nextMobile {
        pointer-events: none;
    }
}
