@use '../../modules/variables' as *;
@use '../../modules/commons' as *;

/* roboto-slab-100 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 100;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-100.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-100.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-100.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-100.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-200 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 200;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-200.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-200.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-200.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-200.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-regular - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-regular.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-300 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 300;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-300.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-300.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-300.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-500 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-500.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-500.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-500.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-600 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-600.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-600.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-600.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-700 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-700.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-700.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-700.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-800 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 800;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-800.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-800.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-800.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-800.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}
/* roboto-slab-900 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    src: url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-900.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-900.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-900.ttf')
            format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/roboto-slab/roboto-slab-v13-latin-ext_latin-900.svg#RobotoSlab')
            format('svg'); /* Legacy iOS */
}