@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st7-nav-menu {
    position: absolute;
    top: rem($header-height-dsk);
    width: 100%;
    height: calc(100vh - #{rem($header-height-dsk)});
    pointer-events: none;

    display: none;
    @include respond-above(l) {
        display: block;
    }

    &--open {
        pointer-events: all;
    }

    &__menu {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--color-white);
        max-height: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        overflow: hidden;
        height: auto;

        &--open {
            opacity: 1;
            max-height: rem(467px);
            pointer-events: all;
            box-shadow: alpha(--color-black, 0.2) 0px 3px 6px;
        }
    }

    &__container {
        @extend %container;
        padding-top: rem(30px);
        display: flex;
        flex: 1 1 auto;
        padding-bottom: rem(35px);
        height: rem(358px);
        justify-content: space-between;
    }

    &__links {
        flex: 1 1;
        a {
            color: var(--color-black);
        }
    }

    &__linksList {
        list-style: none;
        padding-left: 0;
        display: flex;
        height: 100%;

        & > li {
            width: rem(168px);
            margin-right: rem(24px);
            @extend %font-h6;
            font-weight: 500;

            &:first-child {
                border-right: 1px solid var(--color-salmon-rose);
                width: rem(210px);
                padding-right: rem(24px);
            }
        }
    }

    &__linksSubList {
        list-style: none;
        padding-left: 0;

        & > li {
            width: rem(168px);
            margin-top: rem(10px);
            @extend %font-paragraph;
            font-weight: 400;

            &:first-child {
                margin-top: rem(13px);
            }
        }
    }

    &__linksMainList {
        list-style: none;
        padding-left: 0;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;

        & > li {
            @extend %font-h6;
            font-weight: 500;
            margin-right: 0;
            width: rem(192px);
            margin-bottom: rem(16px);
            padding-right: rem(24px);
        }
    }

    &__links--two &__linksMainList {
        margin-top: rem(-1 * 16px);
        display: flex;
        height: 100%;

        & > li {
            margin-top: rem(16px);
            margin-bottom: 0;
        }
    }

    &__links--two &__linksSubList {
        margin-top: rem(-1 * 10px);
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        flex-direction: column;
        width: rem(336px);

        & > li {
            @extend %font-paragraph;
            font-weight: 400;
            margin-top: rem(10px);
            margin-bottom: 0;
            padding-right: rem(24px);
        }
    }

    &__linksList &__linkFirstPlus {
        width: rem(336px);
    }

    &__evidence {
        flex: 0 0 auto;
        padding-top: rem(25px);
        padding-bottom: rem(24px);
        background-color: var(--color-ex-slight-off-white);
        height: rem(109px);
    }

    &__evidenceSlider {
        @extend %container;
    }

    &__evidenceSlide {
        display: flex;
        align-items: center;
    }

    &__evidenceSlideImg {
        width: rem(89px);
        height: rem(60px);
        object-fit: cover;
        margin-right: rem(9px);
    }

    &__evidenceSlideTitle {
        @extend %font-label;
        font-weight: 600;
        width: rem(130px);
        color: var(--color-black);
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: rem(60px);
    }

    &__sliderContainer {
        flex: 1 1 0;
        min-width: 0;
        .st6-slider__paginator {
            display: none;
        }
    }

    &__slide {
        width: rem(293px);
    }

    &__slideImg {
        width: 100%;
        height: rem(191px);
        object-fit: cover;
    }

    &__slideImgTitle {
        @extend %headingReset;
        @extend %font-h6;
        font-weight: 600;
        margin-top: rem(20px);
    }

    &__slideLink {
        margin-top: rem(8px);
        @extend %font-hyperlink;
        font-weight: 600;
        color: var(--color-light-brown);
        text-decoration: underline;
    }
}
