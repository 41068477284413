@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st18-hero-template {
    &__container {
        position: relative;
    }

    &__pic {
        width: 100%;
        height: rem(500px);
    }

    &__textWrapper {
        position: absolute;
        width: 100%;
        bottom: rem(68px);
        @include respond-above(l) {
            bottom: rem(40px);
        }
    }

    &__text {
        @extend %container;
    }

    &__title {
        @extend %font-h3;
        font-size: rem(20px);
        font-weight: 300;
        line-height: rem(32px);
        margin-top: rem(4px);
        max-width: rem(289px);

        & > a {
            & > h2 {
                display: inline;
                @extend %headingReset;
                @extend %font-roboto;
                @extend %font-h1;
                font-size: rem(20px);
                line-height: rem(32px);
                font-weight: 700;
                color: var(--color-black);
                background: alpha(--color-white, 0.6);
                box-shadow: 7px 0 0 alpha(--color-white, 0.6), -7px 0 0 alpha(--color-white, 0.6);
                
                @include respond-above(l) {
                    font-size: rem(48px);
                    line-height: rem(72px);
                }
            }
        }

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(51px);
            font-weight: 500;
            max-width: rem(535px);
            margin-top: rem(4px);
        }
    }

    &__category {
        @extend %font-label;
        font-weight: 500;
        line-height: 1.5;
        background-color: var(--color-salmon-rose);
        box-shadow: 5px 0 0 alpha(--color-salmon-rose, 1), -5px 0 0 alpha(--color-salmon-rose, 1);
    }
}
