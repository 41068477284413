@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st11-actions {
    font-size: rem(24px);

    &__btn {
        @extend %buttonReset;
        background-color: var(--color-white);
        border-radius: 50%;
        box-shadow:
            0 1px 1px 0 rgba(51, 51, 51, 0.15),
            0 0 1px 0 rgba(51, 51, 51, 0.2);
        cursor: pointer;

        & > span {
            font-size: rem(16px);
            color: var(--color-light-brown);
            pointer-events: none;
        }

        &--circles {
            height: rem(40px);
            width: rem(40px);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--share {
            & > span {
                font-weight: 700;
            }
        }
    }

    &__btnLabel {
        @extend %font-label-v2;
        margin-left: rem(5px);
    }

    &__btnCnt {
        display: inline-block;
        position: relative;
        margin-right: rem(12px);

        &:last-child {
            margin-right: 0;
        }

        &--label {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &__shares {
        font-size: rem(14px);

        pointer-events: none;
        opacity: 0;
        border-radius: 5px;
        position: absolute;
        padding: 0 rem(10px);
        width: rem(197px);
        height: auto;
        background-color: var(--color-sandy-beach);
        z-index: setZ(dropdown);

        box-shadow: 0px 1px 2px 0px var(--color-black);
        top: calc(100% + 5px);
        transition: opacity 0.3s ease-in-out;

        &--show {
            pointer-events: all;
            opacity: 1;
        }

        &--toRight {
            left: 0;
        }

        &--toLeft {
            right: 0;
        }

        &--onTop {
            top: unset;
            bottom: calc(100% + 10px);
        }
    }
}
