@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.ss4-masthead {
    --topbar-height: #{rem(48px)};
    height: rem($header-height);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: var(--color-white);
    z-index: setZ(header);
    transition: top 0.8s ease-in-out;
    display: flex;
    flex-flow: column;

    @include respond-above(l) {
        height: rem($header-height-dsk);
    }

    &--scrolled {
        box-shadow: alpha(--color-black, 0.2) 0px 3px 6px;
        opacity: 1;
    }

    &--scrolled &__filters {
        box-shadow: alpha(--color-black, 0.2) 0px 3px 6px;
    }

    &--hidden {
        top: rem(-1 * $header-height);
        box-shadow: none;

        @include respond-above(l) {
            top: rem(-1 * $header-height-dsk);
        }
    }

    &--navOpened {
        box-shadow: none;
    }

    &__topbar {
        transition: height 0.3s ease-in-out;
        overflow: hidden;
        height: var(--topbar-height);
        position: relative;
        order: 1;

        @include respond-above(l) {
            order: 0;
        }
    }

    &__hamburger {
        position: relative;
        left: -20px;
        height: 60px;
        width: 60px;
        overflow: hidden;
        cursor: pointer;
        transform: scale(calc(2/3)); // 60px -> 40px

        span {
            display: block;
            background: var(--color-ground-brown);
            border-radius: 3px;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }

        #hamburger {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        #hamburger span {
            width: 30px;
            height: 3px;
            position: relative;
            top: 18px;
            left: 15px;
            margin: 4px 0;
        }

        #hamburger span:nth-child(1) {
            -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
        }

        #hamburger span:nth-child(2) {
            -webkit-transition-delay: 0.625s;
            transition-delay: 0.625s;
        }

        #hamburger span:nth-child(3) {
            -webkit-transition-delay: 0.75s;
            transition-delay: 0.75s;
        }

        #cross {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        #cross span {
            width: 30px;
            height: 2px;
            position: absolute;
            margin: 4px 0;
        }

        #cross span:nth-child(1) {
            left: -100px;
            top: -100px;
            -webkit-transition-delay: 0s;
            transition-delay: 0s;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        #cross span:nth-child(2) {
            left: -100px;
            bottom: -100px;
            -webkit-transition-delay: 0.25s;
            transition-delay: 0.25s;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &--open {
            #hamburger span {
                left: -100px;
            }

            #hamburger span:nth-child(1) {
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
            }

            #hamburger span:nth-child(2) {
                -webkit-transition-delay: 0.125s;
                transition-delay: 0.125s;
            }

            #hamburger span:nth-child(3) {
                -webkit-transition-delay: 0.25s;
                transition-delay: 0.25s;
            }

            #cross span:nth-child(1) {
                top: 24.5px;
                left: 15px;
                -webkit-transition-delay: 0.625s;
                transition-delay: 0.625s;
            }

            #cross span:nth-child(2) {
                bottom: 24.5px;
                left: 15px;
                -webkit-transition-delay: 0.375s;
                transition-delay: 0.375s;
            }
        }

        @include respond-above(l) {
            display: none;
        }
    }

    &__logo {
        text-align: center;
        height: rem(40px);

        & > a {
            display: block;
            height: rem(40px);
        }
    }

    &__logo-icon {
        display: inline-block;
        background-image: url('../../../../../../resources/saporie/images/logo.svg');
        width: rem(148px);
        height: rem(40px);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;

        @include respond-above(l) {
            left: -24px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        z-index: 1;
    }

    &__btn {
        @extend %buttonReset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: rem(4px);
        transition: all 0.3s ease-in-out;

        @include respond-above(l) {
            justify-content: flex-end;
        }

        &--hidden {
            display: none !important;
        }

        & .label,
        & .label-close {
            display: none;

            @include respond-above(l) {
                display: block;
            }
        }
    }

    &__btnOpen {
        display: block;
    }

    &__btnClose {
        display: none;

        & .label {
            color: var(--color-white);
        }
    }

    &--search &__btn--search &__btnOpen,
    &__btn--opened &__btnOpen {
        display: none;
    }

    &--search &__btn--search &__btnClose,
    &__btn--opened &__btnClose {
        display: block;
    }

    &__btn--search {
        display: none;
        margin-right: rem(34px);

        @include respond-above(l) {
            display: flex;
        }
    }

    &__btn--login {
        font-weight: bold;
        color: var(--color-light-brown);
        text-decoration: underline;

        @include respond-above(l) {
            margin-right: 0;
        }
    }

    &__btn--logged {
        width: rem(24px);
        height: rem(24px);
        margin-right: 1.25rem;

        @include respond-above(l) {
            margin-right: 0;
        }

        & .label {
            display: inline-block;
        }
        & .label-close,
        & .icon-close {
            display: none;
        }
    }

    &__btn--register {
        display: none;
        font-weight: bold;
        padding: rem(20px) rem(30px);
        border: 2px solid var(--color-light-brown);
        border-radius: rem(12px);
        text-align: center;
        line-height: rem(12px);
        height: rem(56px);
        margin-left: rem(16px);

        @include respond-above(l) {
            display: block;
        }
    }

    &__menuOpen {
        & .label,
        & .icon {
            display: none;
        }
        & .label-close {
            display: inline-block;
        }
        & .icon-close {
            display: inline-block;
        }
    }

    &__navMenu {
        z-index: 2;
    }

    &__nav {
        display: none;

        @include respond-above(l) {
            display: block;
        }
    }

    &__navItems {
        list-style: none;
        margin-bottom: 0;
        display: flex;

        & > li {
            display: block;
            position: relative;
            margin-right: rem(20px);

            @include respond-above(xl) {
                margin-right: rem(39px);
                font-size: rem(14px);
            }

            hr {
                border-top: 4px solid var(--color-light-brown);
                margin-top: 4px;
                margin-bottom: 0;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &:hover,
            &.active,
            &.selected {
                hr {
                    opacity: 1;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__navItem {
        @extend %buttonReset;
        font-size: rem(16px);
        font-weight: 500;
        color: var(--color-black);
        text-align: center;
        letter-spacing: 0.2px;
        cursor: pointer;
    }

    &__searchBar {
        display: none;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        background-color: var(--color-white);// var(--color-sandy-beach);
        overflow: hidden;
        height: rem(64px);
        transition: max-height 0.3s ease-in-out;
        pointer-events: none;

        @include respond-above(m) {
            height: rem(230px);
            max-height: 0;
        }

        @include respond-above(l) {
            display: flex;
            z-index: 1000;
            position: absolute;
            top: rem($header-height-dsk);
            left: 0;
        }

        &--open {
            max-height: rem(230px);
            pointer-events: all;
        }
    }

    &--search &__searchBar {
        display: flex;
        pointer-events: all;
        @include respond-above(m) {
            max-height: rem(230px);
        }
        @include respond-above(l) {
            z-index: 0;
        }
    }

    &--search#{&}--scrolled &__searchBar {
        display: none;
    }

    &__searchForm {
        display: flex;
        @extend %container;
        max-width: rem(904px);
    }

    &__searchInputContainer {
        position: relative;
        flex: 1 0 auto;
    }

    &__searchInput {
        
        font-weight: 400;
        height: rem(32px);
        line-height: rem(22px);
        background: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        color: var(--color-black);
        padding-bottom: rem(10px);
        border-bottom: 1px solid var(--color-black);
        width: 100%;

        @include respond-above(m) {
            display: block;
            font-size: rem(24px);
            line-height: rem(33px);
            height: rem(42px);
            padding-bottom: rem(9px);
        }
    }

    &__searchCancelLabel{
        @extend %font-label;
        font-weight: 400;
        padding-left: rem(7px);
        line-height: 0;
        color: var(--color-black);

        @include respond-above(l) {
            display: none;
        }
    }

    &__searchCancel {
        position: absolute;
        display: none;
        right: 0;
        top: 0;
        cursor: pointer;
        line-height: rem(22px);
        
        @include respond-above(m) {
            line-height: rem(33px);
        }
    }

    &__searchInput:not(:placeholder-shown) ~ &__searchCancel {
        display: flex;
        align-items: center;
    }

    &__searchSubmit {
        display: none;
        @include respond-above(m) {
            display: block;
        }

        @extend %buttonReset;
        flex: 0 0 auto;
        font-weight: 600;
        @extend %font-link;
        color: var(--color-black);
        padding-top: rem(17px);
        padding-bottom: rem(19px);
        text-align: center;
        min-width: rem(133px);
        background-color: var(--color-dark-yellow);
        box-shadow: -5px 7px 0px 0px alpha(--color-dark-yellow, 0.5);
        margin-left: rem(31px);
        cursor: pointer;
    }

    &__searchFound {
        @extend %font-paragraph;
        @extend %container;
        max-width: rem(904px);

        display: none;
    }

    &--search &__searchFound {
        display: none;
        @include respond-above(m) {
            display: block;
        }
    }

    &__filters {

        @include respond-above(m) {
            position: absolute;
            top: rem($header-height-dsk);
        }

        .st9-filters__form {
            @extend %container;
            justify-content: center;
            padding-block: rem(16px);
        }
    }

    &--search &__filters {
        @include respond-above(m) {
            top: rem($header-height + 230);
        }
        @include respond-above(l) {
            top: rem($header-height-dsk + 230);
        }
    }

    &--search#{&}--scrolled &__filters {
        @include respond-above(m) {
            top: rem($header-height);
        }
        @include respond-above(l) {
            top: rem($header-height-dsk);
        }
    }
}
