@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.st1-recipe-card {
    width: 100%;
    box-shadow:
        0 4px 8px -2px rgba(171, 147, 67, 0.15),
        0 0 1px 0 rgba(171, 147, 67, 0.2);
    border-radius: rem(12px);
    overflow: hidden;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;

    &__media-container {
        position: relative;
    }

    &__media {
        width: 100%;
        height: rem(111px);

        @include respond-above(m) {
            height: rem(240px);
        }
    }

    &__top {
        position: absolute;
        inset: rem(16px) rem(16px) auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: rem(16px);
    }

    &__tag {
        --_height: #{rem(32px)};
        height: var(--_height);
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: var(--_height);
        padding-inline: rem(8px);
        color: var(--color-white);
        background-color: #003761;
        border-radius: rem(8px);
    }

    &__ctas {
        margin-left: auto;
        display: flex;
        flex-direction: row;
    }

    &__partnership {
        width: 100%;
        min-height: rem(40px);
        padding: rem(9px) 0 rem(9px) rem(18px);
        background-color: var(--color-salmon-rose);
        position: absolute;
        bottom: 0;
        left: 0;

        @include respond-below(l) {
            min-height: rem(4px);
            padding: rem(2px) 0 rem(2px) rem(14px);
        }
    }

    &__partnershipText {
        @extend %font-paragraph;
        margin-bottom: 0;
        color: var(--color-black);

        @include respond-below(l) {
            display: none;
        }
    }

    &__partnershipLabel {
        font-weight: 600;
        text-decoration: underline;
        color: var(--color-black);

        @include respond-below(l) {
            font-size: rem(12px);
        }
    }

    &__collectionTitle {
        font-weight: 600;
        &::before {
            content: '| ';
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        background-color: var(--color-white);
        padding-top: rem(17px);
        padding-bottom: rem(18px);
        padding-right: rem(4px);
        padding-left: rem(4px);

        @include respond-above(m) {
            padding-right: rem(15px);
            padding-left: rem(15px);
        }
    }

    &__texts {
        flex: 1 1 auto;
        color: var(--color-black);
        text-decoration: none;
        margin-bottom: rem(38px);
    }

    &__title {
        @extend %font-h6;
        font-size: rem(16px);
        font-weight: 600;
        text-align: left;
        color: var(--color-black);
    }

    &__description {
        @extend %font-description;
        display: none;
        font-weight: normal;
        text-align: left;
        line-height: 1.54;
        letter-spacing: 0.19px;
        color: var(--color-brillant-grey);
        margin-bottom: 0;
        margin-top: rem(16px);

        @include respond-above(l) {
            display: block;
        }

        &--visible {
            display: block;
        }
    }

    &__breadcrumb {
        @extend %font-label;
        font-weight: 700;
        margin-bottom: rem(8px);
        color: var(--color-light-brown);
    }

    &__label {
        @extend %font-h6;
        font-size: rem(12px);
        font-weight: normal;
        white-space: nowrap;

        @include respond-above(m) {
            font-size: rem(14px);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
    }

    &__infos {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: rem(24px);
        flex-wrap: wrap;
        margin-bottom: rem(20px);
    }

    &__difficulty {
        display: inline-flex;
        column-gap: rem(4px);
        align-items: center;
        height: rem(20px);
    }

    &__icon {
        width: rem(14px);
        height: rem(14px);

        &--faded {
            opacity: 30%;
        }

        & > span {
            font-size: rem(14px);
        }
    }

    &__authImage {
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        width: rem(24px);
        height: rem(24px);
        margin-right: rem(10px);
    }

    &__authInfo {
        @extend %font-h6;
        font-size: rem(12px);
        font-weight: 700;
    }

    &__chef {
        @extend %font-paragraph;
        display: flex;
        flex-direction: row;
        margin: rem(2px) rem(7px) rem(1px) rem(3px);

        @include respond-above(l) {
            margin: rem(4px) rem(16px) rem(2px) rem(7px);
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include respond-above(l) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    @include respond-below(m) {
        &--mobileLarge {
            height: 100%;
        }
        &--mobileLarge &__media-container {
            height: rem(197px);
        }
        &--mobileLarge &__media {
            width: 100%;
            height: 100%;
        }
        &--mobileLarge &__description {
            display: block;
        }
    }
}
