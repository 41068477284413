@use './variables' as *;
@use './mixins' as *;
@use './functions' as *;
@use "sass:map";

//
//  CONTAINER
//––––––––––––––––––––––––––––––––––––––––––––––––––

%container {
    width: 100%;
    padding: 0 rem($container-wpad);
    margin: 0 auto;

    @include respond-above(m) {
        max-width: #{rem(map.get($containers, 'm'))};
    }

    @include respond-above(l) {
        max-width: #{rem(map.get($containers, 'l'))};
    }

    @include respond-above(xl) {
        max-width: #{rem(map.get($containers, 'xl'))};
    }
}

//
//  COMPONENT RESET
//––––––––––––––––––––––––––––––––––––––––––––––––––

%headingReset {
    margin-bottom: 0;
}

%buttonReset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    border-radius: 0;
    text-align: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Blue outline removal (not very accessible) */
    &:focus {
        outline: none;
    }

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

//
//  FONTS FAMILY
//––––––––––––––––––––––––––––––––––––––––––––––––––

//DM Sans
%dmSansFont {
    font-family: 'DM Sans';
}

%dmSansFontThin {
    @extend %dmSansFont;
    font-weight: 100;
}

%dmSansFontLight {
    @extend %dmSansFont;
    font-weight: 300;
}

%dmSansFontRegular {
    @extend %dmSansFont;
    font-weight: 400;
}

%dmSansFontMedium {
    @extend %dmSansFont;
    font-weight: 500;
}

%dmSansFontBold {
    @extend %dmSansFont;
    font-weight: 700;
}

%dmSansFontBlack {
    @extend %dmSansFont;
    font-weight: 900;
}

//Roboto Slab
%robotoFont {
    font-family: 'Roboto Slab';
}

%robotoFontThin {
    @extend %robotoFont;
    font-weight: 100;
}

%robotoFontLight {
    @extend %robotoFont;
    font-weight: 300;
}

%robotoFontRegular {
    @extend %robotoFont;
    font-weight: 400;
}

%robotoFontMedium {
    @extend %robotoFont;
    font-weight: 500;
}

%robotoFontBold {
    @extend %robotoFont;
    font-weight: 700;
}

%robotoFontBlack {
    @extend %robotoFont;
    font-weight: 900;
}

%font-body {
    @extend %dmSansFont;
}

// deprecated: fallback to font-body
%font-display {
    @extend %font-body;
}

%font-roboto {
    font-family: 'Roboto Slab';
}

//
//  FONTS STYLE
//––––––––––––––––––––––––––––––––––––––––––––––––––

%font-h1 {
    font-size: rem(48px);
    line-height: rem(52px);
}

%font-h1Resp {
    font-size: rem(24px);
    line-height: rem(30px);

    @include respond-above(l) {
        font-size: rem(48px);
        line-height: rem(52px);
    }
}

%font-h2 {
    font-size: rem(32px);
    line-height: rem(36px);
}

%font-h3 {
    font-size: rem(24px);
    line-height: rem(30px);
}

%font-h4 {
    font-size: rem(20px);
    line-height: rem(26px);
}

%font-h5 {
    font-size: rem(18px);
    line-height: rem(22px);
}

%font-h6 {
    font-size: rem(16px);
    line-height: rem(22px);
}

%font-paragraph {
    font-size: rem(14px);
    line-height: rem(20px);
    letter-spacing: rem(0.19px);
}

%font-description {
    font-size: rem(13px);
    line-height: 1.54;
    letter-spacing: rem(0.19px);
}

%font-label {
    font-size: rem(12px);
    line-height: rem(18px);
}

%font-hyperlink {
    font-size: rem(12px);
    line-height: rem(18px);
}

%font-ced-h2 {
    @extend %font-display;
    font-size: rem(32px);
    line-height: rem(36px);
}

%font-ced-h3 {
    @extend %font-display;
    font-size: rem(24px);
    line-height: rem(30px);
}

%font-ced-h4 {
    @extend %font-display;
    font-size: rem(20px);
    line-height: rem(26px);
}

%font-ced-label {
    @extend %font-display;
    font-size: rem(18px);
    line-height: rem(18px);
}

%font-link {
    font-size: rem(14px);
    line-height: rem(14px);
}

%font-18Fix {
    font-size: rem(18px);
    line-height: rem(24px);
}

%font-20Fix {
    font-size: rem(20px);
    line-height: rem(24px);
}

%font-20Resp {
    font-size: rem(16px);
    line-height: rem(20px);

    @include respond-above(l) {
        font-size: rem(20px);
        line-height: rem(24px);
    }
}

%font-24Resp {
    font-size: rem(20px);
    line-height: rem(28px);

    @include respond-above(l) {
        font-size: rem(24px);
        line-height: rem(32px);
    }
}

%font-26Resp {
    font-size: rem(24px);
    line-height: rem(28px);

    @include respond-above(l) {
        font-size: rem(26px);
        line-height: rem(30px);
    }
}

%font-26Fix {
    font-size: rem(26px);
    line-height: rem(30px);
}

%font-32Resp {
    font-size: rem(28px);
    line-height: rem(32px);

    @include respond-above(l) {
        font-size: rem(32px);
        line-height: rem(36px);
    }
}

%font-32Resp24 {
    font-size: rem(24px);
    line-height: rem(28px);

    @include respond-above(l) {
        font-size: rem(32px);
        line-height: rem(36px);
    }
}

%font-48Fix {
    font-size: rem(48px);
    line-height: rem(48px);
}

%font-48Resp {
    font-size: rem(32px);
    line-height: rem(36px);

    @include respond-above(l) {
        font-size: rem(48px);
        line-height: rem(52px);
    }
}

%font-48Alt {
    font-size: rem(32px);
    line-height: rem(26px);

    @include respond-above(l) {
        font-size: rem(48px);
        line-height: rem(52px);
    }
}

%font-120Resp {
    font-size: rem(72px);
    line-height: rem(72px);

    @include respond-above(l) {
        font-size: rem(120px);
        line-height: rem(120px);
    }
}

%font-label-v2 {
    font-size: rem(14px);
    line-height: rem(18px);
}

// BASIC STYLES

@mixin btn-primary {
    --btn-height: #{rem(52px)};
    @extend %buttonReset;
    @extend %dmSansFontBold;
    font-size: rem(16px);
    line-height: var(--btn-height);
    height: var(--btn-height);
    width: 100%;
    color: var(--color-white);
    background-color: var(--color-brown);
    border-radius: rem(12px);
    padding-inline: rem(20px);
    text-align: center;
    cursor: pointer;

    @include respond-above(l) {
        width: fit-content;
    }

    &:hover, &:focus-visible {
        background-color: alpha(var(--color-brown), 90%);
    }
}

%btn-primary {
    @include btn-primary;
}

.button-primary {
    @extend %btn-primary;
}

@mixin btn-tertiary {
    --btn-height: #{rem(56px)};
    --border-width: 2px;
    @extend %buttonReset;
    @extend %dmSansFontBold;
    font-size: rem(16px);
    line-height: calc(var(--btn-height) - 2 * var(--border-width));
    height: var(--btn-height);
    width: 100%;
    color: var(--color-brown);
    background-color: var(--color-white);
    border: 2px solid var(--color-light-brown);
    border-radius: rem(12px);
    padding-inline: rem(30px);
    text-align: center;
    cursor: pointer;

    @include respond-above(l) {
        width: fit-content;
    }

    &:hover, &:focus-visible {
        color: var(--color-light-brown);
    }
}

%btn-tertiary {
    @include btn-tertiary;
}

.button-tertiary {
    @extend %btn-tertiary;
}

@mixin checkbox {
    cursor: pointer;
    position: relative;
    padding-left: rem(32px);
    display: inline-block;
    line-height: rem(16px);
    margin-bottom: 0;

    .checkbox {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .label {
        --checkbox-size: #{rem(21px)};
        @extend %font-paragraph;
        font-weight: 500;
        line-height: var(--checkbox-size);

        &::before {
            content: '';
            position: absolute;
            width: var(--checkbox-size);
            height: var(--checkbox-size);
            border: 1px solid alpha(var(--color-black), 30%);
            border-radius: rem(5px);
            background-color: var(--color-white);
            vertical-align: middle;
            left: 0;
            top: 0;
            display: inline-block;
        }

        &:hover::before {
            border-color: var(--color-light-brown);
        }

        &::after {
            display: none;
            @extend %sc-icon-base;
            content: $sc-icon-tick;
            position: absolute;
            left: 1px;
            right: calc(100% - 20px);
            top: 1px;
            bottom: calc(100% - 20px);
            color: var(--color-white);
            font-size: rem(12px);
            line-height: calc(var(--checkbox-size) - 1px);
            background-color: var(--color-light-brown);
            border-radius: rem(4px);
            padding-left: rem(4px);
        }
    }

    .checkbox:checked + .label::after {
        display: block;
    }
    .checkbox:checked + .label::before {
        border-color: var(--color-light-brown);
    }
}

%sc-icon-base {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
