@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st22-poi-map-card {
    @extend %container;
    background-color: var(--color-white);
    width: 100%;
    
    min-height: rem(190px);

    &__selected { 
        border: solid 1.5px var(--color-brown);
    }

    display: flex;
    flex-direction: column;

    &__pic {
        display: inline-block;
        overflow: hidden;
        border-radius: 100%;
        width: rem(80px);
        height: rem(80px);
    }

    &__media {
        width: 100%;
        margin-right: rem(26px);
    }

    &__content {
        margin-top: rem(17px);
        margin-bottom: rem(17px);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__texts {
        color: var(--color-black);
    }

    &__title {
        @extend %font-h6;
        font-weight: 600;
        text-align: left;
        color: var(--color-black);
    }

    &__address {
        @extend %font-label;
        font-weight: normal;
        margin-bottom: rem(3px);
    }

    &__description {
        margin-bottom: 0;
        @extend %font-paragraph;
        font-weight: normal;
        text-align: left;
        color: var(--color-brillant-grey);
    }

    &__breadcrumb {
        @extend %font-label;
        font-weight: 500;
        color: var(--color-black);
    }

    &__separator {
        width: 100%;
        height: rem(1px);
        margin-top: rem(21px);
        margin-bottom: rem(22px);
        opacity: 0.16;
        background-color: var(--color-brown);
    }

    &__appr {
        @extend %font-label;
        font-weight: 600;
        text-decoration: underline;
        color: var(--color-light-brown);
        margin-bottom: rem(26px);
        justify-content: flex-start;
    }

    &__info {
        .st21-poi-info__goto , .st21-poi-info__location {
            flex-direction: row;
        }
    }

}
