@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use "sass:map";

.ss10-glossary {
    @extend %container;
    @include respond-above(l) {
        margin-top: 0;
    }

    &__breadcrumbstyle {
        margin-bottom: 0;
        @include respond-above(l) {
            margin-bottom: rem(31px);
        }
    }

    &__title {
        display: block;
        @extend %headingReset;
        @extend %font-roboto;
        @extend %font-h1;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: rem(27px);

        @include respond-above(l) {
            margin-top: 0;
            font-size: rem(48px);
            line-height: rem(48px);
            margin-bottom: rem(52px);
        }
    }

    &__anchors {
        width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        border-bottom: rem(1px) solid rgb(75, 58, 51, 0.32);
        transition: top 0.8s ease-in-out;

        @include respond-above(xl) {
            flex-direction: column;
            max-width: #{rem(map.get($containers, 'xl'))};
            padding: 0 rem($container-wpad);
            margin: 0 auto;
        }

        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }

        &--list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        &--link {
            white-space: nowrap;
            display: inline;
            text-transform: uppercase;
            color: var(--color-black);
            opacity: 0.5;
            font-weight: 500;
            margin: 0 rem(15px);

            @include respond-above(l) {
                margin: 0 rem(15px);
            }

            &:hover,
            &:active,
            &:visited {
                color: var(--color-black);
                opacity: 1;
                font-weight: 600;
            }
        }

        &--item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            margin-right: rem(5px);
            padding-bottom: rem(16px);

            @include respond-above(l) {
                margin-right: 0;
            }
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        padding-top: rem(20px);
        max-width: unset;
        width: 100vw; // viewport width
        background-color: white;
        z-index: 9;
    }

    &--belowHeader {
        top: rem($header-height);

        @include respond-above(l) {
            top: rem($header-height-dsk);
        }
    }

    &--activeLink {
        color: var(--color-black);
        opacity: 1;
        font-weight: 600;
    }

    &--active {
        border-bottom: rem(3px) solid var(--color-black);
    }
}
