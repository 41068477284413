@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.ss12-footer {
    $root: &;

    background-color: var(--color-brown);
    width: 100%;
    min-height: 300px;
    color: var(--color-white);
    padding-top: rem(24px);
    padding-bottom: rem(32px);

    &__topContainer {
        @extend %container;
    }

    &__top {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        width: calc(100% + rem(2 * $container-wpad));
        margin-left: rem(-1 * $container-wpad);

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    &__topLink {
        display: flex;
        flex-direction: column;
        padding: rem(32px) rem(8px) rem(12px);
        border-radius: rem(12px);
        width: rem(88px);
        flex: 0 0 auto;
        justify-content: space-between;
        align-items: center;
        background-color: alpha(var(--color-darkest), 0.2);
        margin-right: rem(8px);
        & > span {
            letter-spacing: normal;
        }

        &:first-of-type {
            margin-left: rem(16px);
        }

        &:last-of-type {
            margin-right: rem(16px);
        }

        @include respond-above(l) {
            width: rem(144px);
            padding: rem(24px) rem(8px) rem(12px);
        }

        @include respond-above(xl) {
            width: rem(204px);
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: rem(20px) rem(24px) rem(20px) rem(24px);
        }
    }

    &__topLinkIcon {
        font-size: rem(24px);
        color: var(--color-white);
        margin-bottom: rem(28px);

        @include respond-above(l) {
            margin-bottom: rem(16px);
        }

        @include respond-above(xl) {
            margin-bottom: 0;
            margin-right: rem(24px);
        }
    }

    &__topLinkLabel {
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-white);

        @include respond-above(l) {
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    &__links {
        //margin-top: rem(32px);
        @include respond-above(l) {
            display: flex;
            flex: 1 1 auto;
            margin-left: rem(85px);
        }

        @include respond-above(xl){
            margin-left: rem(368px);
        }
    }

    &__linkContainer {
        margin-top: rem(32px);
        @include respond-above(l) {
            margin-top: 0;
        }
    }

    &__linkHeading {
        @extend %dmSansFontBold;

        cursor: pointer;
        height: rem(24px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span {
            color: alpha(--color-white, 0.6);
            display: block;
        }
    }

    &__linkOpener {
        
        font-size: rem(24px);

        & > span {
            pointer-events: none;
        }

        & > .close {
            display: none;
        }

        @include respond-above(l) {
            display: none;
        }
    }

    &__linkContent {
        margin-top: rem(32px);
        max-height: 0;
        height: auto;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;

        @include respond-above(l) {
            max-height: rem(250px);
            transition: none;
        }
    }

    &__linkItem {
        @extend %dmSansFontMedium;
        display: block;
        margin-bottom: rem(24px);
        @include respond-above(l) {
            width: rem(243px);
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    &__linkContainer--open {
        #{$root}__linkContent {
            max-height: rem(250px);
        }

        #{$root}__linkOpener {
            & > .open {
                display: none;
            }

            & > .close {
                display: block;
            }
        }
    }

    &__about {
        margin-top: rem(56px);
        @include respond-above(l) {
            margin-top: 0;
        }
    }

    &__support {
        margin-top: rem(32px);
    }

    &__supportCta {
        @extend %dmSansFontBold;
        background-color: var(--color-white);
        color: var(--color-black);
        border-radius: rem(12px);
        padding: rem(12px) rem(24px);
        font-size: rem(14px);
        line-height: rem(16px);
        cursor: pointer;
        display: inline-block;
        width: 100%;
        justify-content: center;
        text-align: center;

        @include respond-above(l) {
            width: auto;
            display: inline-flex;
        }
    }
    &__supportCta:hover {
        background-color: var(--color-white);
    }
    &__supportCta:focus-visible {
        border: 1px solid var(--color-black);
    }

    &__about {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: alpha(--color-white, 0.6);
        
        b {
            color: var(--color-white);
            @extend %dmSansFontBold;
        }

        @include respond-above(l) {
            width: rem(315px);
        }
    }

    &__middle {
        @include respond-above(l) {
            display: flex;
            flex-direction: row-reverse;
            margin-top: rem(64px);
        }
    }

    &__middleAbout {
        flex: 0 0 auto;
    }

    &__bottomContainer {
        @extend %container;
        margin-top: rem(34px);

        @include respond-above(l) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__separator {
        border: 0;
        border-top: 1px solid alpha(--color-white, 0.6);
        margin-bottom: rem(32px);
        flex: 1 0 100%;

        @include respond-above(l) {
            margin-bottom: rem(24px);
        }
    }

    &__social {
        @include respond-above(l) {
            display: flex;
            align-items: center;
        }
    }

    &__socialLabel {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: alpha(--color-white, 0.6);
    }

    &__socialItems {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        @include respond-above(l) {
            margin-top: 0;
            margin-left: rem(24px);
        }
    }

    &__socialItem {
        margin-right: rem(16px);
        height: rem(24px);
        width: rem(24px);
        display: inline-block;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__app {
        margin-top: rem(40px);
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: alpha(--color-white, 0.6);

        @include respond-above(l) {
            margin-top: 0;
            display: flex;
            align-items: center;
        }
    }

    &__appItems {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        @include respond-above(l) {
            margin-top: 0;
            margin-left: rem(24px);
        }
    }

    &__appItem {
        margin-right: rem(5px);
        display: inline-block;
        height: rem(33px);

        picture, img {
            height: inherit;
        }
    }

    &__copyright {
        margin-top: rem(40px);
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: alpha(--color-white, 0.6);

        @include respond-above(l) {
            margin-top: 0;
        }
    }
}