@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.sc13-widget-hp-2 {
    @extend %container;

    &__innerContentTop {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        @include respond-above(m) {
            flex-direction: row;
        }
    }

    &__media {
        width: 100%;
        height: rem(326px);
        margin-bottom: rem(30px);

        @include respond-above(m) {
            width: 100%;
            height: rem(440px);
            margin-bottom: 0;
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;

        @include respond-above(m) {
            margin-right: rem(50px);
        }
    }

    &__slider {
        padding-top: rem(94px);
        @include respond-above(l) {
            padding-top: rem(58px);
        }

        .st6-slider__pagination {
            display: flex;

            @include respond-above(l) {
                display: none;
            }
        }
        .st6-slider__btn {
            &--prev {
                display: none;
            }
            &--next {
                display: none;
            }
        }
    }

    &__breadcrumb {
        @extend %font-label;
        font-weight: 500;
        text-decoration: underline;
        text-decoration-color: var(--color-salmon-rose);
        text-decoration-thickness: rem(2px);
        text-underline-offset: rem(3.5px);
        margin-bottom: rem(20px);
    }

    &__title {
        @extend %font-ced-h2;
        font-size: rem(36px);
        font-weight: normal;
        width: rem(341px);
        margin-bottom: rem(16px);
    }

    &__description {
        @extend %font-paragraph;
        width: rem(310px);
        min-height: rem(128px);
    }

    &__button {
        width: rem(328px);
        height: rem(51px);
        margin: 0 0 rem(7px) rem(10px);
        color: var(--color-dark-yellow);
        background-color: var(--color-dark-yellow);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: -10px 7px 0px 0px alpha(--color-dark-yellow, 0.29);

        @include respond-above(l) {
            width: rem(256px);
        }
    }

    &__buttonLabel {
        
        font-size: rem(14px);
        font-weight: 600;
        line-height: rem(14px);
        text-decoration: none;
        color: var(--color-black);
        text-align: center;
        letter-spacing: rem(0.5px);
    }

    &__cards {
        display: flex;
        flex-direction: row;
    }
}
