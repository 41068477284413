@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.sc2-recipe-in-evi {
    overflow: hidden;
    border-radius: rem(12px);

    &__container {
        position: relative;
    }

    &__media {
        height: 23.75rem;

        @include respond-above(l) {
            width: 100%;
            height: 31.125rem;
        }

        &--video,
        &--picture {
            & > a {
                display: block;
                height: 100%;
            }
        }

        &--video .st16-video {
            height: inherit;
        }

        &--picture .st12-picture {
            height: inherit;
        }
    }

    &__recTitle {
        @extend %font-h4;
        position: absolute;
        bottom: auto;
        right: rem(60px);
        bottom: rem(45px);
        left: rem(32px);
        line-height: 1.7;
        min-height: rem(33px);

        & > a {
            & > h3 {
                color: var(--color-black);
                @extend %font-h4;
                line-height: 1.7;
                font-weight: 600;
                display: inline;
                background: alpha(--color-white, 0.8);
                box-shadow: 10px 0 0 alpha(--color-white, 0.8), -10px 0 0 alpha(--color-white, 0.8);

                @include respond-above(l) {
                    font-size: rem(24px);
                }
            }
        }

        @include respond-above(l) {
            font-size: rem(24px);
            right: rem(270px);
            bottom: rem(63px);
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__label {
        @extend %font-label;
        margin: 0 rem(10px);
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
    }

    &__infos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        left: rem(12px);
        bottom: rem(18px);
        top: auto;
        right: auto;
        color: var(--color-white);

        @include respond-above(l) {
            left: rem(31px);
            bottom: rem(33px);
            top: auto;
            right: auto;
        }
    }

    &__icon {
        width: rem(19px);
        height: rem(19px);
    }

    &__chips {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: absolute;
        top: rem(32px);
        left: rem(32px);
        bottom: rem(434px);
        right: auto;
    }

    &__chip {
        min-height: rem(25px);
        margin: rem(4px) rem(7px) rem(4px) rem(5px);
        padding: rem(2px) rem(17px) rem(8px) rem(16px);
        border-radius: rem(16px);
        border: solid 2px var(--color-salmon-rose);
        background-color: var(--color-white);
    }

    &__chipText {
        @extend %font-label;
        color: var(--color-black);
        text-decoration: none;
        display: flex;
    }

    &__ctas {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: rem(14px);
        position: absolute;
        top: rem(28px);
        right: rem(4px);
        left: auto;
        bottom: auto;

        @include respond-above(l) {
            flex-direction: row;
            right: rem(16px);
        }
    }
}
