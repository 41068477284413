@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-hero {
    width: 100%;

    &__topBar {
        display: block;
        @extend %container;
        margin-bottom: rem(20px);
    
        @include respond-above(l) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__title {
        display: block;
        @extend %headingReset;
        @extend %font-roboto;
        @extend %font-h1;
        margin: rem(21px) rem(48px) 0 0;
        
        font-size: rem(24px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;

        @include respond-above(l) {
            margin: 0;
            font-size: rem(48px);
            line-height: rem(48px);
            width: 80%;
        }
    }

    &__ctaWrapper {
        display: flex;
        flex-direction: row;
        margin-top: rem(14px);

        @include respond-above(l) {
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0;
        }
    }

    &__cta {
        position: relative;

        &:nth-child(2) {
            margin-left: rem(20px);
        }
    }

    &__ctaIconText {

        display: flex;
        justify-content: flex-start;

        &--text {
            height: rem(18px);
            margin: 0 0 rem(1px) rem(2px);
            
            font-size: rem(12px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: rem(18px);
            letter-spacing: normal;
        }

        &--icon {
            margin-right: rem(4px);
            width: rem(18px);
            height: rem(18px);
        }
        &--icon:hover {
            cursor: pointer;
        }
    }

    &__shareContent {
        position: absolute;
        top: rem(17px);
        left: rem(-17px);

        @include respond-above(l) {
            left: rem(-124px);
        }
    }

    &__heroMedia {
        height: rem(211px);

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &__heroImage, &__heroVideo {
            width: 100%;
            object-fit: cover;
            height: rem(211px);
            
            @include respond-above(l) {
                height: rem(500px);
            }
    }

    /* embedded youtube video height */
    &__heroVideo {
        height: rem(211px);

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &__ctas {
        display: flex;
        flex-direction: row;
    }
}