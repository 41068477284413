@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    :target {
        @apply scroll-mt-32 lg:scroll-mt-48;
    }

    button {
        text-align: inherit;
    }

    [x-cloak] {
        display: none !important;
    }

    [data-theme='verso-natura'] {
        --color-primary1: #00592c;
        --color-neutral: #f8f6ef;
    }

    [data-theme='piacersi'] {
        --color-primary1: #194a5b;
        --color-neutral: #f8f6ef;
    }

    [data-theme='baby'] {
        --color-primary1: #8f993e;
        --neutral: #f8f6ef;
    }

    [data-theme='conad'] {
        --color-primary1: #da291c;
        --color-neutral: #f8f6ef;
    }

    [data-theme='sapori-e-idee'] {
        --color-primary1: #81272c;
        --color-neutral: #f8f6ef;
    }

    [data-theme='11-paralleli'] {
        --color-primary1: #435c59;
        --color-neutral: #f8f6ef;
    }

    [data-theme='essentiaelab'] {
        --color-primary1: #3e4827;
        --color-neutral: #f8f6ef;
    }

    [data-theme='alimentum'] {
        --color-primary1: #978c87;
        --color-neutral: #f8f6ef;
    }

    [data-theme='pet-friends'] {
        --color-primary1: #594747;
        --color-neutral: #f8f6ef;
    }

    [data-theme='parafarmacia'] {
        --color-primary1: #12727b;
        --color-neutral: #f8f6ef;
    }

    [data-theme='essentiae'] {
        --color-primary1: #3e4827;
        --color-neutral: #f8f6ef;
    }

    [data-theme='sapori-e-dintorni'] {
        --color-primary1: #003663;
        --color-neutral: #f8f6ef;
    }
}

@layer components {
    .prose {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            @apply mb-2 last:mb-0;
        }
    }

    .container {
        @apply w-full md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1384px];
    }

    .btn-primary {
        @apply font-bold text-base leading-[52px] h-[52px] w-full lg:w-fit text-white bg-brown rounded-xl px-[20px] text-center cursor-pointer hover:bg-brown hover:opacity-90 focus-visible:bg-brown focus-visible:opacity-90;
    }

    .btn-tertiary {
        @apply font-bold text-base leading-[52px] h-[56px] w-full lg:w-fit text-brown bg-white border-[2px] border-solid border-light-brown rounded-xl px-[30px] text-center cursor-pointer hover:text-light-brown focus-visible:text-light-brown;
    }

    .btn-saporie {
        @apply flex justify-center items-center pr-7 pl-7 relative shadow-[-10px_7px_0_0_rgba(247,194,96,0.29)] h-[3.1875rem] bg-darkYellow text-sm leading-[0.875rem] text-black no-underline tracking-[0.03125] text-center font-semibold;
    }

    .btn-tag {
        @apply text-slate-50 bg-buttonlinkTagChip text-sm pl-[30px] pr-[30px] py-[18px] rounded font-bold inline-block;
    }

    .separator {
        @apply w-8 h-1 rounded border-none bg-yellow;
    }

    .btn-reset {
        @apply border-none m-0 p-0 w-auto overflow-auto bg-transparent rounded-none leading-normal;
    }
}
