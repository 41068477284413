@use '../../modules/variables' as *;
@use '../../modules/commons' as *;

/* dm-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-regular.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-regular.svg#DMSans')
            format('svg'); /* Legacy iOS */
}
/* dm-sans-italic - latin-ext_latin */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    src: url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-italic.woff') format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-italic.svg#DMSans')
            format('svg'); /* Legacy iOS */
}
/* dm-sans-500 - latin-ext_latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    src: url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''), url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500.woff') format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500.svg#DMSans')
            format('svg'); /* Legacy iOS */
}
/* dm-sans-500italic - latin-ext_latin */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 500;
    src: url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500italic.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-500italic.svg#DMSans')
            format('svg'); /* Legacy iOS */
}
/* dm-sans-700 - latin-ext_latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    src: url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700.woff') format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700.svg#DMSans')
            format('svg'); /* Legacy iOS */
}
/* dm-sans-700italic - latin-ext_latin */
@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    src: url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700italic.woff')
            format('woff'),
        /* Modern Browsers */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('#{$resourcesFolder}/saporie/fonts/dm-sans/dm-sans-v6-latin-ext_latin-700italic.svg#DMSans')
            format('svg'); /* Legacy iOS */
}